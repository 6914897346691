import { template as template_91d25ffd8ddb4dbfb2882395e6baa4fc } from "@ember/template-compiler";
const FKText = template_91d25ffd8ddb4dbfb2882395e6baa4fc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
