import { template as template_4494c160f49340cb914e6b3bbcc55cc8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4494c160f49340cb914e6b3bbcc55cc8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
