import { template as template_4ac37d1f0aab4872bdbc269762e3e21c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_4ac37d1f0aab4872bdbc269762e3e21c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
